import dateFormat from "dateformat"
import { Link } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import { blog } from "../../lib/urls"
import "./NewsListingBanner.scss"
import ScrollAnimation from "react-animate-on-scroll"

const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const NewsListingBanner = ({ newsData, strapi_id }) => {
  const {
    banner_image,
    category,
    date,
    imagetransforms,
    read_time,
    slug,
    topics,
    title,
    video_url,
    content,
  } = newsData

  let processedImages
  processedImages =
    imagetransforms?.banner_image_Transforms || JSON.stringify({})

  const imagename = "blog.banner_image.details"
  return (
    
    <div className="featured-news-section" key={strapi_id}>
    <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
    {(newsData.banner_image||newsData.title)&&
      <Container>
        <div className="image-content-news-banner">
          <div className="image-section">
            <Link href={`${blog}${slug}/`}>
              {/* <ImageModule
                ImageSrc={banner_image}
                altText={`${title}`}
                imagetransforms={processedImages}
                renderer="pic-src"
                imagename={imagename}
                strapi_id={strapi_id}
                classNames="img-fluid"
              /> */}
              <img src={banner_image?.url} alt={`${title}`} classNames="img-fluid"/>
            </Link>
          </div>
          <div className="text-section">
            <Link href={`${blog}${slug}/`}>
              <h1>{title}</h1>
            </Link>
            <div className="time-date">
              <p className="time">{read_time}</p>
              {read_time && <span>/</span>}
              <p className="date">{dateFormat(date, "mmm dd, yyyy")}</p>
            </div>
            <p className="description">
              <ContentModule Content={content?.data?.content} />
            </p>
            <Link to={`${blog}${slug}/`}>
              <button className="button button-outline-grey">
                continue reading
              </button>
            </Link>
          </div>
        </div>
      </Container>
      }
      </ScrollAnimation>
    </div>
  )
}

export default NewsListingBanner
