import { Link } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import "./NewsStaticCard.scss"
import ScrollAnimation from "react-animate-on-scroll"
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const {
  CTALink,
} = require("@starberry/gatsby-theme-utils/Modules/CTALinkModule")

const NewsStaticCard = ({ cardData }) => {

  const handleTop=()=>{
    if(typeof window!=="undefined"){
    window.scrollTo({top:0})
    }
  }
  return (
    <div className={`news-static-card`}>
      <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
      <Container>
        <div className="card-section_news">
          {cardData.map(data => {
            return (
              <div className="card_news-list">
                <div className="content-container">
                  <h3>{data.title}</h3>
                  <p className="description">
                    <ContentModule Content={data?.content?.data?.content} />
                  </p>
                  <div className="card_cta">

                 {data?.cta?.title==="subscribe now"?
                   <a target="_blank" href={data?.cta?.custom_link} class="button button-filled-green">
                    <i className="icon youtube-icon-black"/>
                     {data?.cta?.title}
                     </a> 
                   :
                   <a onClick={handleTop} href={data?.cta?.custom_link} class="button button-filled-green">
                     {data?.cta?.title}
                     </a> 
                    }
                    </div>
                </div>
              </div>
            )
          })}
        </div>
      </Container>
      </ScrollAnimation>
    </div>
  )
}

export default NewsStaticCard
