import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
// import NewsListing from "../components/NewsListing/NewsListing"
import NewsStaticCard from "../components/NewsStaticCard/NewsStaticCard"
import NewsListingBanner from "../components/NewsListingBanner/NewsListingBanner"
import NewsListing1 from "../components/NewsListing/NewsListing1"
import SEO from "../components/seo"


const NewsLandingTemplate = ({ data }) => {
  const allNews = data?.allStrapiBlog.edges
  const id = data?.strapiPage.strapi_id;

  const blogCategories=data?.allStrapiBlogCategorie?.edges;
  const blogTopics=data?.allStrapiBlogTopic?.edges;

  const subscriptionData=data?.allStrapiSiteConfig?.edges[0];


  const PageData=data.strapiPage

  const getBlogData=PageData.add_page_modules?.filter((item,i)=>{
    if(item?.__typename ===
      "STRAPI__COMPONENT_COMPONENTS_BLOG_RELATION"){
        return item.select_blog
      }
  })

  const getBlog=getBlogData?.length>0&&getBlogData[0]?.select_blog;
  const sortedBlog=allNews?.filter(news=>news?.node?.slug!==getBlog?.slug)

  let popUpData=""

  if(PageData?.add_page_modules?.length>0){
     const results=PageData?.add_page_modules?.filter(item=>item.__typename==="STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT")
     if(results?.length>0){
      popUpData=results[0]
     }
  }

  return (
    <Layout popUpData={popUpData} dark={true} popularSearch={PageData?.select_popular_search?.title}>
      <div className="layout-padding-top"></div>
      <div className="news-landing-template">
        {/*  */}
        
        {PageData.add_page_modules?.map((item, i) => {
        return (
          <div>
             {/* {item?.__typename ===
              "STRAPI__COMPONENT_COMPONENTS_BLOG_RELATION" && (
                <NewsListingBanner newsData={item.select_blog} strapi_id={id}/>
            )} */}
             {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" && item?.select_module==="blog"&& (
              <NewsListing1
              data={sortedBlog} 
              strapi_id={id}
              subscriptionData={subscriptionData}
              blogCategories={blogCategories}
              blogTopics={blogTopics}
              newsData={getBlog}
              />
            )}
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_STATIC_CARD_ITEMS" && (
              <NewsStaticCard cardData={item.cards} />
            )}
          </div>
        )
      })}
      </div>
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage
  return (
      <SEO title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} 
      imageUrl="https://ggfx-handh3.s3.eu-west-2.amazonaws.com/i.prod/hh_logo_on_green_background_93feeb7b8b.png"

      />
  )
}

export default NewsLandingTemplate

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment
      add_page_modules {

        ... on STRAPI__COMPONENT_COMPONENTS_BLOG_RELATION {
          __typename
          id
          select_blog {
            ...BlogFragment
          }
        }

        ... on STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT {
          __typename
          ...GlobalPopUpFragment
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
          id
          __typename
          select_module
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_STATIC_CARD_ITEMS {
          __typename
          id
          cards {
            title
            content {
              data {
                content
              }
            }
            cta {
              title
              custom_link
              link_type
              link {
                slug
                strapi_parent {
                  slug
                }
              }
            }
          }
        }
      }

    }


    allStrapiSiteConfig {
      edges {
        node {
          subscription_card {
            cta {
              link {
                slug
              }
              custom_link
              content {
                data {
                  content
                }
              }
              link_type
              title
            }
            content {
              data {
                content
              }
            }
            title
          }

          podcast_data {
            cta {
              link {
                slug
              }
              custom_link
              content {
                data {
                  content
                }
              }
              link_type
              title
            }
            content {
              data {
                content
              }
            }
            title
            image {
              url
            }
          }
        }
      }
    }

    allStrapiBlogCategorie(sort: { fields: sort, order: ASC }) {
      edges {
        node {
          slug
          blog_category
        }
      }
    }

    allStrapiBlogTopic {
      edges {
        node {
          slug
          topic_name
        }
      }
    }

    allStrapiBlog(
      filter: { publish: { eq: true } }
      sort: { fields: date, order: ASC }
    ) {
      edges {
        node {
          title
          slug
          strapi_id
          read_time
          content {
            data {
              content
            }
          }
          video_url
          date
          tile_image {
            url
          }
          banner_image {
            url
          }
          
          imagetransforms {
            tile_image_Transforms
          }
         
          blog_categories {
            blog_category
            slug
          }
          blog_topics {
            topic_name
            slug
          }
        }
      }
    }
  }
`
